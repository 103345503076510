import get from 'lodash.get'

const translations = {
  common: {
    no: {
      layout: {
        lang: 'Språk',
        home: 'Forside',
        about: 'Om oss',
        advisors: 'Rådgivere',
        job: 'Jobb',
        contact: 'Kontakt',
        description:
          'Cost Partner er et konsulentselskap som arbeider med besparelser på alle indirekte, administrative kostnader. Disse kostnadene, gjerne betegnet som ikke-strategiske kostnader, omfatter de fleste typiske driftsutgifter. Meget betydelige beløp kan erfaringsmessig spares.',
        login: 'Logg inn',
      },
      home: {
        lead: 'Konsulentselskap innen kostnadsbesparelser. No cure, no pay.',
        categories: 'Kostnadskategorier',
        testimonials: [
          [
            'Cost Partners spisskompetanse og profesjonalitet har bidratt til kostnadsbesparelser vi selv ikke hadde kapasitet til å oppnå.',
            'Roger Lunde',
            'Adm.dir/CEO',
            'Brødrene Dahl AS',
          ],
          [
            'Cost Partners grundige arbeid med analyse og konkurranseutsetting har bidratt til å redusere våre kostnader betydelig på flere innkjøpsområder.',
            'Ole Henrik Waldenstrøm',
            'Innkjøpsdirektør',
            'Mesta AS',
          ],
          [
            'Cost Partner reduserte våre kostnader på facility services med 40%, uten å belaste vår administrasjon.',
            'Petter Schouw-Hansen',
            'CFO/Strategi- og finansdir.',
            'Kid Interiør AS',
          ],
        ],
      },
      about: {
        heading: 'Om Cost Partner Norge AS',
        body: [
          'Cost Partner er et konsulentselskap som arbeider med besparelser på alle indirekte, administrative kostnader. Disse kostnadene, gjerne betegnet som ikke-strategiske kostnader, omfatter de fleste typiske driftsutgifter. Meget betydelige beløp kan erfaringsmessig spares.',
          'Cost Partner er ikke en innkjøpspool eller medlemsorganisasjon og har ingen bindinger til noen leverandører.',
          'Vi utfører analyser innenfor et 20-talls forskjellige kostnadskategorier som; kontor- og datarekvisita, trykksaker, telefoni, emballasje, frakt, kantinedrift og renhold. En omfattende analyseprosess og vår unike database med bl.a. produkter, priser og leverandørinformasjon, samt et betydelig innkjøpsvolum, gir besparelser som selskaper ikke kan oppnå på egen hånd.',
          'Den gjennomsnittlige besparelsen ligger på ca 30%. Dette oppnås kostnads- og risikofritt for kunden, fordi vi kun beregner honorar når besparelse oppnås. No cure – no pay.',
        ],
      },
      advisors: {
        heading_no: 'Rådgivere - Norge',
        heading_se: 'Rådgivere - Sverige',
      },
      job: {
        heading: 'Jobb',
        notice: 'Vi søker flere rådgivere!',
        body: [
          [
            'Arbeidsoppgaver',
            'Salg og prosjektledelse innen kostnadsbesparelser for bedrifter, organisasjoner og institusjoner innen et eget geografisk område. Presentasjon, avtaleinngåelse og prosjektledelse på kostnadsprosjekter i nært samarbeid med våre rådgivere og analytikere.',
          ],
          [
            'Kvalifikasjoner',
            'Høyere utdannelse og B2B-erfaring fra næringslivet, gjerne innen finansielle produkter eller tjenester. Gode kommunikasjonsevner og analytisk legning.',
          ],
          [
            'Vi tilbyr',
            'Du blir som rådgiver del av et fellesskap, men er samtidig helt selvstendig (næringsdrivende). Du får full opplæring i konseptet, innsalg og metodikk og får tilgang til alle nødvendige hjelpemidler og en egen mentor. Den rette kan påregne et betydelig inntektspotensiale.',
          ],
          [
            'Kontakt',
            'Ta kontakt med Tom Nordvang på telefon 99 70 81 61 eller {e-post:tom.nordvang@costpartner.no}, eller Espen Orderud på telefon 93 05 04 50 eller {e-post:espen.orderud@costpartner.no}.',
          ],
        ],
      },
      contact: {
        heading: 'Kontakt',
        address: 'Adresse',
        email: 'E-post',
        phone: 'Telefon',
        notice: 'Kontakt meg for en kostnadsfri benchmarking av våre innkjøp:',
        submitted:
          'Takk for din henvendelse. Vi tar kontakt så raskt som mulig.',
        error:
          'Det oppsto dessverre et problem ved innsending av skjema. Vennligst ta kontakt via kontaktinformasjonen til høyre.',
        submit: 'Send',
        company: 'Firma',
        name: 'Kontaktperson',
        comment: 'Kommentar',
      },
    },

    se: {
      layout: {
        lang: 'Språk',
        home: 'Start',
        about: 'Om oss',
        advisors: 'Rådgivare',
        job: 'Lediga jobb',
        contact: 'Kontakt',
        description:
          'Cost Partner är ett konsultföretag som stödjer sina kunder med att reducera indirekta administrativa kostnader. Dessa kostnader benämns ofta som icke-strategiska och rör utgifter nödvändiga för den dagliga driften men som ej kan kopplas till bolagets operativa verksamhet.',
        login: 'Logga in',
      },
      home: {
        lead: 'Konsulter för ökad kostnadseffektivitet. No cure - no pay.',
        categories: 'Kostnadskategorier',
        testimonials: [
          [
            'Cost Partners spetskompetens och expertis har bidragit till kostnadsbesparingar, som vi själva inte hade kapacitet att uppnå.',
            'Roger Lunde',
            'Adm.dir/CEO',
            'Brødrene Dahl AS',
          ],
          [
            'Kostnadseffektiv drift förutsätter att vi prioriterar ”Core Business”. Cost Partner har bearbetat kostnader inom kontor, lager och transport, med tydliga resultatförbättringar.',
            'Odd Nordli',
            'Adm.dir',
            'Trelleborg Industrial Products Norway AS',
          ],
          [
            'Cost Partner reducerade våra kostnader för Facility Services med 40%, utan att belasta vår administration.',
            'Petter Schouw-Hansen',
            'CFO/Strategi- og finansdir.',
            'Kid Interiør AS',
          ],
        ],
      },
      about: {
        heading: 'Om Cost Partner',
        body: [
          'Cost Partner är ett konsultföretag som stödjer sina kunder med att reducera indirekta administrativa kostnader. Dessa kostnader benämns ofta som icke-strategiska och rör utgifter nödvändiga för den dagliga driften men som ej kan kopplas till bolagets operativa verksamhet.',
          'Cost Partner är ej en inköpspool eller medlemsorganisation och har inte band till leverantörer. Vi utför analyser inom cirka 20 olika kostnadskategorier som kontorsvaror, datormaterial, trycksaker, telefoni, emballage, catering och städning.',
          'En omfattande analysprocess och vår upparbetade kunskap om produkter, priser och jämförelsetal ger besparingar som företagen inte kan uppnå på egen hand.',
          'De genomsnittliga kostnadsreduktionen är över 30%. Detta uppnås kostnads​​- och riskfritt för kunden, då ersättning betalas i efterhand när besparingar har uppnåtts. No cure - no pay.',
        ],
      },
      advisors: {
        heading_no: 'Rådgivare - Norge',
        heading_se: 'Rådgivare - Sverige',
      },
      job: {
        heading: 'Lediga jobb',
        notice: 'Vi söker rådgivare!',
        body: [
          [
            'Ansvar',
            'Försäljning och projektledning inom kostnadsreduktion för företag, organisationer och institutioner inom ett geografisk område i nära samarbete med våra analytiker.',
          ],
          [
            'Kvalifikation',
            'Högre utbildning och lång B2B-erfarenhet. God kommunikationsförmåga och ett väletablerat nätverk.',
          ],
          [
            'Vi erbjuder',
            'Varje rådgivare har eget företag och styr detta oberoende men får ändå styrkan av en större organisation. Du får fullständig utbildning i konceptet, försäljningsarbete och metodik samt har tillgång till alla nödvändiga verktyg och en dedikerad mentor.',
          ],
          [
            'Kontakt',
            'Vänligen kontakta Per-Erik Påhlman via {e-post:per-erik.pahlman@cost-partner.se}.',
          ],
        ],
      },
      contact: {
        heading: 'Kontakt',
        address: 'Adress',
        email: 'E-post',
        phone: 'Telefon',
        notice:
          'Kontakta mig för en kostnadsfri benchmarking av våra inköpskostnader:',
        submitted: 'Tack, vi kommer att kontakta dig inom kort.',
        error:
          'Det oppsto dessverre et problem ved innsending av skjema. Vennligst ta kontakt via kontaktinformasjonen til høyre.',
        submit: 'Skicka',
        company: 'Företag',
        name: 'Kontaktperson',
        comment: 'Kommentar',
      },
    },

    en: {
      layout: {
        no: 'Norwegian',
        en: 'English',
        lang: 'Language',
        home: 'Home',
        about: 'About',
        advisors: 'Advisors',
        job: 'Jobs',
        contact: 'Contact',
        description:
          'Cost Partner is a consulting company that works with saving all indirect administrative costs. These costs, commonly referred to as non-strategic costs, include the most typical operating expenses. Our experience shows that very significant amounts can be saved.',
        login: 'Login',
      },
      home: {
        lead:
          'Consultant company in the area of cost savings. No cure - no pay.',
        categories: 'Cost categories',
        testimonials: [
          [
            'Cost Partners expertise and professionalism has contributed to saving costs we had no capasity to achieve ourselves.',
            'Roger Lunde',
            'Adm.dir/CEO',
            'Brødrene Dahl AS',
          ],
          [
            'Cost Partners grundige arbeid med analyse og konkurranseutsetting har bidratt til å redusere våre kostnader betydelig på flere innkjøpsområder.',
            'Ole Henrik Waldenstrøm',
            'Innkjøpsdirektør',
            'Mesta AS',
          ],
          [
            'Cost Partner reduced our costs on facility services by 40%, without taking up our administrations time.',
            'Petter Schouw-Hansen',
            'CFO/Strategi- og finansdir.',
            'Kid Interiør AS',
          ],
        ],
      },
      about: {
        heading: 'About Cost Partner AS',
        body: [
          'Cost Partner is a consulting company that works with saving all indirect administrative costs. These costs, commonly referred to as non-strategic costs, include the most typical operating expenses. Our experience shows that very significant amounts can be saved.',
          'Cost Partner is not a purchasing pool or member organization, and has no links to any suppliers.',
          'We perform analyzes within a 20´s different cost categories such as; office and computer supplies, printed matter, telephony, packaging, freight, canteens and cleaning services. An extensive analysis process and our unique database of products, prices and supplier information, as well as a significant purchasing volume, provides savings that companies can not achieve on their own.',
          'The average savings is abour 30%. This achieved cost and risk free for the customer because we only calculate fees when savings are achieved. No cure – no pay.',
        ],
      },
      advisors: {
        heading_no: 'Advisors - Norway',
        heading_se: 'Advisors - Sweeden',
      },
      job: {
        heading: 'Jobs',
        notice: 'We seek more consultants',
        body: [
          [
            'Tasks',
            'Sales and project management within cost savings for companies, organizations and institutions within their own geographical area. Presentation, contract signing and project management on cost projects in close cooperation with our own consultants and analysts.',
          ],
          [
            'Qualifications',
            'Higher education and B2B experience from business, preferably within financial products or services. Good communication skills and analytical learning.',
          ],
          [
            'We can offer',
            'As a consultant, you become part of a community, but at the same time you are completely independant (entrepeneurial). You get full training in the concept, sales and methodology and get access to all the necessary aids and your own mentor. The right person can expect a significant income potential.',
          ],
          [
            'Contact',
            'Contact Tom Nordvang by telephone 99 70 81 61 or {e-mail:tom.nordvang@costpartner.no}, or Espen Orderud by telephone 93 05 04 50 or {e-mail:espen.orderud@costpartner.no}.',
          ],
        ],
      },
      contact: {
        heading: 'Contact us',
        address: 'Address',
        email: 'E-mail',
        phone: 'Phone',
        notice: 'Call me for a conversation on cost savings:',
        submitted: 'Your message is now sent and will be processed shortly.',
        error:
          'Unfortunately, there was a problem submitting the form. Please contact us via the contact information on the right.',
        submit: 'Submit',
        company: 'Company',
        name: 'Contact person',
        comment: 'Comment',
      },
    },
  },
  no: {
    common: {
      layout: {
        no: 'Norwegian',
        en: 'English',
        linkedin: 'https://www.linkedin.com/company/5008517',
        facebook: 'https://www.facebook.com/costpartneras',
        company: 'Cost Partner Norge AS',
        address: 'Karihaugveien 89, 1086 Oslo',
        phone: '+47 22 30 93 40',
        email: 'post@costpartner.no',
      },
    },
    no: {},
    en: {},
  },
  se: {
    common: {
      layout: {
        se: 'Svenska',
        linkedin: 'https://www.linkedin.com/company/11410827',
        facebook: 'https://www.facebook.com/costpartneras',
        company: 'Cost Partner',
        address: 'Skomakaregatan 6, 223 50 Lund',
        phone: '+46 0722-23 80 80',
        email: 'post@cost-partner.se',
      },
    },
    se: {},
  },
}

export function initI18n(instance, lang) {
  return function i18n(key, defaultValue = null) {
    const commonLang = translations.common[lang]
    const instanceCommon = translations[instance.id].common
    const instanceLang = translations[instance.id][lang]
    return (
      get(instanceLang, key) ||
      get(instanceCommon, key) ||
      get(commonLang, key) ||
      defaultValue ||
      key
    )
  }
}
