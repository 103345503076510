import React from 'react'

import './Container.css'

const Container = ({ children, textWidth }) => (
  <div className="container">
    {textWidth ? <div className="text-container">{children}</div> : children }
  </div>
)

export default Container
