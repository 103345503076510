import React from 'react'
import { Link } from 'gatsby'
import Container from './Container'
import Email from './Email'
import Image from './Image'

import './Footer.css'

const Footer = ({ i18n, instance, lang }) => {
  return (
    <div className="footer">
      <Container>
        <div className="columns">
          <div>
            <div className="address">
              <strong>{i18n('layout.company')}</strong>
              <br />
              {i18n('layout.address')}
              <br />
              {i18n('layout.phone')}
              <br />
              <Email email={i18n('layout.email')} />
            </div>
            <div className="languages">
              {i18n('layout.lang')}:{' '}
              {instance.languages.map(language => (
                <Link key={language} to={`/${instance.id}/${language}`}>
                  {i18n(`layout.${language}`)}
                </Link>
              ))}
            </div>
            <div className="countries">
              <Link to="/no/no/">
                <Image src="no.png" alt="Norge" />
              </Link>
              <Link to="/se/se/">
                <Image src="se.png" alt="Sverige" />
              </Link>
            </div>
            <div className="login">
              <a
                href={`https://basen.costpartner.no/login/${instance.id}`}
                rel="nofollow"
              >
                {i18n('layout.login')}
              </a>
            </div>
          </div>

          <div>
            <ul className="subnav">
              <li>
                <Link to={`/${instance.id}/${lang}/`}>
                  {i18n('layout.home')}
                </Link>
              </li>
              <li>
                <Link to={`/${instance.id}/${lang}/about/`}>
                  {i18n('layout.about')}
                </Link>
              </li>
              <li>
                <Link to={`/${instance.id}/${lang}/advisors/`}>
                  {i18n('layout.advisors')}
                </Link>
              </li>
              <li>
                <Link to={`/${instance.id}/${lang}/job/`}>
                  {i18n('layout.job')}
                </Link>
              </li>
              <li>
                <Link to={`/${instance.id}/${lang}/contact/`}>
                  {i18n('layout.contact')}
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <ul className="social">
              <li>
                <a
                  href={i18n('layout.linkedin')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href={i18n('layout.facebook')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              {instance.id === 'no' && (
                <li>
                  <Link to="/no/no/relatertinnhold">Relevant innhold</Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
