import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Container from './Container'
import Footer from './Footer'
import Image from './Image'
import Affix from './Affix'
import { initI18n } from '../translations'

import './Layout.css'

const Layout = ({ children, pageContext, large = false }) => {
  const { instance, lang, page, post } = pageContext
  const headerClassName = ['main-header', large ? 'large' : ''].join(' ').trim()
  const navOffset = large ? 380 : 120

  const i18n = initI18n(instance, lang)

  const headerText = () => {
    if (instance.id === 'se') {
      return (
        <div className="quote">
          Det sista året har vi minskat
          <br />
          våra kunders kostnader
          <br />
          med mer än 30 %
        </div>
      )
    }

    if (lang === 'en') {
      return (
        <div className="quote">
          Det siste året har vi kuttet
          <br />
          våre kunders kostnader
          <br />
          med mer enn 30 %
        </div>
      )
    }

    return (
      <div className="quote">
        Det siste året har vi kuttet
        <br />
        våre kunders kostnader
        <br />
        med mer enn 30 %
      </div>
    )
  }

  let title = instance.title
  let url = instance.url
  let description = i18n('layout.description')

  if (page && page.name !== 'home') {
    title = `${i18n(`layout.${page.name}`)} | ${title}`
    url = `${url}/${page.path}`
  } else if (post) {
    title = `${post.frontmatter.title} | ${title}`
    description = post.html.split('\n')[0].replace(/<\/?p>/g, '')
    url = `${url}${post.fields.slug}`
  }

  return (
    <React.Fragment>
      <Helmet>
        <html lang={lang} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta
          property="og:image"
          content={`${instance.url}/img/og-image.jpg`}
        />
      </Helmet>

      <div className={headerClassName}>
        <Container>
          <div className="logo">
            <Link to={`/${instance.id}/${lang}/`}>
              <Image src={`logo-${instance.id}.png`} alt="" />
            </Link>
          </div>

          {large && headerText()}
        </Container>
      </div>

      <Affix className="navigation" offset={navOffset}>
        <Container>
          <ul>
            <li>
              <Link to={`/${instance.id}/${lang}/`}>{i18n('layout.home')}</Link>
            </li>
            <li>
              <Link to={`/${instance.id}/${lang}/about/`}>
                {i18n('layout.about')}
              </Link>
            </li>
            <li>
              <Link to={`/${instance.id}/${lang}/advisors/`}>
                {i18n('layout.advisors')}
              </Link>
            </li>
            <li>
              <Link to={`/${instance.id}/${lang}/job/`}>
                {i18n('layout.job')}
              </Link>
            </li>
            <li>
              <Link to={`/${instance.id}/${lang}/contact/`}>
                {i18n('layout.contact')}
              </Link>
            </li>
          </ul>
        </Container>
      </Affix>

      {children}

      <Footer instance={instance} lang={lang} i18n={i18n} />
    </React.Fragment>
  )
}

export default Layout
