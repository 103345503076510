import React from 'react'

class Email extends React.Component {
  emailAsHtmlEntities() {
    return this.props.email.replace(/./gm, s => "&#" + s.charCodeAt(0) + ";")
  }

  handleClick = (e) => {
    e.preventDefault()
    const { email } = this.props
    window.location.href = `mailto:${email}`
  }

  render () {
    const { email, label, ...others } = this.props
    return (
      <a
        className="email"
        onClick={this.handleClick}
        href="mailto:obfuscated"
        dangerouslySetInnerHTML={{ __html: label || this.emailAsHtmlEntities() }}
        {...others}
      />
    )
  }
}

export default Email
